/**
* Styles e-cmdua
**/

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

// Required
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$container-max-widths: (
	sm: 540px,
	md: 752px,
	lg: 972px,
	xl: 1172px
);

$border-radius: 0px;
$btn-border-radius: 0px;
$grid-gutter-width: 32px;

// optional
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/tables';
//@import '~bootstrap/scss/navbar';

/*
todos os imports do bootstrap 4, pegar o que for necessario:

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';
 */

$gray: #525257;
$gray-lighter: #ededee;
$gray-light: #c5c5c9;
$gray-dark: #39393d;
$light: white;
$secondary-background: darken($gray-lighter, 5%);

$primary-color: #007f26;
$secondary-color: #ffdf57;
$default-color: darken($gray-light, 5%);
$danger-color: #d93855;
$texto: $gray;
$texto-secundario: lighten($gray-dark, 35%);
$verde: #009994;
//$verde-sucesso: #017531;
$verde-sucesso: $primary-color;
$amarelo-sucesso: #756401;

$headerheight: 112px;

#app {
	min-height: 100vh;
	width: 100vw;
	margin: auto;
	display: flex;
	flex-direction: column;

	> .container {
		//min-height: calc(100% - #{$headerheight});
		//margin-bottom: -32px;
		padding-bottom: 2rem;
		flex: 1;

		.linha {
			display: flex;
			> label {
				margin-top: 10px;
			}
		}
	}
}

.logo {
	margin-bottom: 0.25rem;
}

.titulo-sistema {
	font-weight: 700;
	font-size: 2rem;
	line-height: 1;
	small {
		font-weight: 400;
		font-size: 1rem;
		margin-left: 0.5rem;
	}
}

.titulo-pagina {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 1.5rem;
	row-gap: 1rem;

	legend {
		width: auto;
	}

	.titulo {
		flex: 1;
		font-size: 1.3rem;
		font-weight: bold;
		text-transform: uppercase;
	}
}

body {
	font-family: 'Lato', 'Helvetica Neue', Verdana, sans-serif;
	font-size: 16px;
	color: $texto;
}

footer {
	background-color: $gray-dark;
	padding: 0.5rem 0;
	margin-top: auto;
	> .container {
		display: flex;
		justify-content: flex-end;
	}
	a {
		line-height: 1;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-weight: 700;
	line-height: 1.1;
	color: inherit;
	//margin-bottom: 1.25rem;
}

h1,
.h1 {
	font-size: 2.5em;
}
h2,
.h2 {
	font-size: 2em;
}
h3,
.h3 {
	font-size: 1.5em;
}
h4,
.h4 {
	font-size: 1.25em;
}
h5,
.h5 {
	font-size: 1em;
}
h6,
.h6 {
	font-size: 0.875em;
}

ul {
	list-style: none;
	&.list {
		padding: 0px;
	}

	> .list-items {
		padding: 10px;
		font-size: 1rem;
		cursor: pointer;

		&.even {
			background-color: $secondary-background;
		}
	}
}

.list-items {
	padding: 10px;
	font-size: 1rem;
	cursor: pointer;

	&.even {
		background-color: $secondary-background;
	}
}

.row.list-items {
	margin-left: 0;
	margin-right: 0;
	padding-right: 0;
	padding-left: 0;
}

a:not(.btn) {
	position: relative;
	font-weight: 700;
	transition: color 0.3s ease 0s;
}

a {
	background-color: transparent;
	text-decoration: none;
	color: $primary-color;
	&:hover,
	&:focus {
		color: darken($primary-color, 15%);
	}
}

@keyframes blinker {
	50% {
		opacity: 0.5;
	}
}
.is-hom {
	&:before {
		content: 'ambiente de homologação';
		font-weight: bold;
		position: absolute;
		text-align: center;
		width: 100%;
		top: 0;
		animation: blinker 1s linear infinite;
	}
}

header {
	padding: 20px 0;
	background-color: #39393d;

	&.is-hom {
		background-color: #412929;
		&:before {
			color: #ff7c7c;
		}
	}

	&.header-admin {
		background-color: $verde;
		&.is-hom {
			background-color: #003d3b;
		}
	}
	//max-height: $headerheight;
	margin-bottom: 20px;
	color: #fff;

	> .container {
		display: flex;
		flex-wrap: wrap;
		column-gap: 2rem;
		row-gap: 1rem;
		align-items: flex-end;
		//justify-content: space-between;
	}

	.btn-link {
		border: none;
		color: lighten($secondary-color, 5%);
		&:hover,
		&:focus {
			background-color: #fff;
			color: $verde;
			text-decoration: none;
		}
	}

	.navbar {
		display: flex;
		justify-content: space-between;
		color: #fff;

		.logo {
			margin-left: 20px;
		}

		.nav-item {
			font-weight: 700;
		}

		.pills {
			display: flex;
			flex: 1;
			flex-wrap: nowrap;

			.nav-pills {
				cursor: pointer;
				color: $light;
				margin: 0px 32px 0px 0px;
				padding: 10px 5px;
				background-color: $primary-color;
				border-radius: 5%;
				min-width: 140px;
				text-align: center;
				font-weight: bold;

				> li {
					margin-right: 32px;
					display: inline-block;
					position: relative;

					button {
						font-size: 14px;
						text-transform: uppercase;
						font-weight: bold;
						white-space: nowrap;
						margin-bottom: 10px;
						display: inline-block;
						cursor: pointer;
						padding: 10px 0 3px 0;
						color: $primary-color;
						border: none;
						background: transparent;

						&:last-child {
							margin-right: 0;
						}

						&[disabled] {
							color: gray !important;
						}
					}
				}

				> li.active > button,
				> li.active > button:hover,
				> li.active > button:focus {
					color: $primary-color;
					background: transparent;
					border-bottom: 2px solid;
					border-radius: 0;
					padding: 10px 0 3px 0;
				}
			}
		}
	}
}

.user {
	display: flex;
	column-gap: 0.5rem;
	font-size: 14px;
	line-height: 1.25;
	@media (min-width: 768px) {
		flex-direction: column;
		align-items: flex-end;
		margin-left: auto;
	}
}

.nav-tabs {
	margin-bottom: 32px;
	> li {
		margin-bottom: -2px;
		color: $primary-color;
		padding: 8px 0 6px;
		border-bottom: 4px solid transparent;
		cursor: pointer;
		font-weight: 700;
		&:not(:last-child) {
			margin-right: 32px;
		}
		&:hover,
		&:focus {
			border-bottom-color: $primary-color;
		}
		&.selected {
			color: $texto;
			border-bottom-color: $texto;
		}
	}
}

*:focus,
button:focus {
	outline: none;
	box-shadow: 0 0 0 1px $light inset, 0 0 0 3px darken($gray-light, 5%) inset;
}

.warning-message,
.error-message,
.vertical .warning-message,
.vertical .error-message {
	margin-left: 16px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	@media (min-width: map-get($grid-breakpoints, 'md')) {
		flex-wrap: nowrap;
	}

	.fa {
		margin-top: 4px;
		margin-right: 8px;
	}
}

.warning-message .download-item {
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: #eee;
	}

	.description {
		flex-grow: 1;
		margin-left: 16px;
		font-weight: 700;
	}

	.actions,
	.cod {
		flex-grow: 0;
	}
}

.buttons {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin-top: 1rem;

	.titulo-pagina & {
		margin: unset;
	}
}

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 2px solid transparent;
	white-space: nowrap;
	user-select: none;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0;
	padding: 0.625rem 0.75rem;
	transition: all 0.5s ease;
	.fa {
		margin-right: 8px;
	}
	&:disabled {
		cursor: not-allowed;
	}
}

.btn-primary {
	color: #fff;
	background-color: $primary-color;
	border-color: transparent;

	&:hover,
	&:focus {
		background-color: darken($primary-color, 10%);
		border-color: darken($primary-color, 10%);
	}

	&:focus {
		background-color: darken($primary-color, 10%);
		outline: 1px solid darken($primary-color, 10%);
		outline-offset: -4px;
	}

	&:active {
		background-color: darken($primary-color, 10%) !important;
		border-color: darken($primary-color, 10%);
		transform: scale(0.99);
	}
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
	border-color: transparent;
}

.btn-secondary {
	color: $gray-dark;
	background-color: $secondary-color;

	&:hover {
		background-color: darken($secondary-color, 10%);
	}
}

.btn-danger {
	color: $light;
	background-color: $danger-color;

	&:hover {
		background-color: darken($danger-color, 10%);
	}
}

.btn-outline-primary {
	border-color: $primary-color;
	border-width: 2px;
	color: $primary-color;
	&:hover,
	&:focus {
		background-color: $primary-color;
		border-color: $primary-color;
		color: $light;
	}
	&.disabled {
		border-color: $gray-light;
		background-color: $gray-lighter;
		color: $gray;
		&:hover,
		&:focus {
			background-color: $gray-lighter;
			color: $gray;
		}
	}
}

.btn-outline-danger {
	border-color: $danger-color;
	border-width: 2px;
}

.btn-default {
	color: $gray-dark;
	background-color: $default-color;

	&:hover {
		background-color: darken($default-color, 10%);
	}
}

.btn-link {
	border: none;
	color: $primary-color;
	background-color: transparent;
	padding-left: 0;
	padding-right: 0;
}

.btn-icon {
	background-color: transparent;

	&:hover {
		background-color: lightgrey;
	}
}

fieldset {
	margin-bottom: 1rem;
	fieldset legend {
		font-size: 1.375em;
		padding-top: 0.25rem;
	}
}

// ERROR SCREEN
.error-screen {
	min-height: calc(100% - #{$headerheight});
	margin-bottom: -32px;
	padding-bottom: 32px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: #333;

	> * {
		width: 340px;
		max-width: 100%;
		text-align: left;
	}

	.error-header {
		font-size: 80px;
		margin-bottom: 48px;
		color: transparentize(#000000, 0.8);

		.error-label {
			color: #333;
			display: block;
			font-size: 32px;
			line-height: 32px;
		}
	}

	.logo {
		margin-top: 48px;

		img {
			width: 196px;
		}
	}
}

// TOOLBAR
.toolbar {
	display: flex;
	margin: 16px 0;
	justify-content: flex-end;
}

// LOADER
.loader-wrapper {
	background-color: rgba(255, 255, 255, 0.7);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span {
		display: inline-block;
		padding: 4px 16px;
		// background-color: rgba(255, 255, 255, 0.5);
	}

	// LOADER
	.loader {
		border-radius: 50%;
		height: 42px;
		width: 42px;
		margin-top: 16px;
		animation: spin 1s infinite linear;
		border: 4px solid $primary-color;
		border-top-color: transparent;
	}

	&.block-loader {
		position: relative;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

// ERRORS AND WARNINGS
ul.error-block {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	list-style: none;
}

.error-block {
	margin-bottom: 16px;
	margin-top: 8px;
}

.error-message {
	margin-left: 0;
	color: darken($danger-color, 25%);

	.fa {
		color: darken($danger-color, 15%);
	}
}

.warning-message {
	margin-left: 5px;
	color: adjust-hue(saturate(darken(yellow, 30%), 20%), -15%);

	.fa {
		color: adjust-hue(saturate(darken(yellow, 10%), 50%), -10%);
	}
}

.info-message {
	margin-left: 5px;
	color: adjust-hue(saturate(darken(cyan, 30%), 20%), -15%);

	.fa {
		color: adjust-hue(saturate(darken(cyan, 10%), 50%), -10%);
	}
}

// PAGINAÇÃO

.contador-paginacao {
	margin-bottom: -1rem;
	margin-top: 1rem;
	text-align: center;
}
.pagination {
	display: block;
	margin: 32px;
	display: flex;
	justify-content: center;
	align-items: center;

	.page {
		cursor: pointer;
		user-select: none;

		&.disabled {
			opacity: 0.5;
		}

		&.active {
			background-color: $primary-color;
			color: $light;
		}
	}
}

// A organizar
.select-wrapper {
	position: relative;

	&:before {
		pointer-events: none;
		position: absolute;
		content: '';
		z-index: 1;
		border-top: none;
		border-right: none;
		border-bottom: 3px solid #c5c5c9;
		border-left: 3px solid #c5c5c9;
		border-bottom-left-radius: 2px;
		width: 10px;
		height: 10px;
		top: 50%;
		right: 16px;
		cursor: pointer;
		-webkit-transform: translateY(-50%) rotate(-45deg);
		-moz-transform: translateY(-50%) rotate(-45deg);
		-o-transform: translateY(-50%) rotate(-45deg);
		transform: translateY(-50%) rotate(-45deg);
	}
}

.radio-inline {
	margin: 0 20px 0 0;
	display: inline-block;

	> [type='radio']:not(:checked),
	> [type='radio']:checked {
		position: absolute;
		left: -9999px;

		+ label {
			position: relative;
			padding-left: 38px;
			cursor: pointer;

			&::before {
				position: absolute;
				left: 0;
				top: -2px;
				width: 24px;
				height: 24px;
				content: '';
				border: 2px solid #c5c5c9;
				background: #fff;
				border-radius: 50%;
			}

			&:hover {
				border-color: #09afa7;
			}

			// &::after {
			// 	position: absolute;
			// 	background-color: #09afa7;
			// 	content: '';
			// 	width: 12px;
			// 	height: 12px;
			// 	top: 4px;
			// 	left: 6px;
			// 	transition: all 0.2s;
			// 	border-radius: 50%;
			// }
		}
	}

	> [type='radio']:checked + label:before {
		border: 2px solid #09afa7;
	}

	> [type='radio']:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}
}

.ant-calendar-picker-input[readonly] {
	background-color: $light !important;
}

.btn-top {
	float: right;
	margin-top: 0px;
	//padding: 8px 20px;

	&.calendar {
		margin-top: -48px;
		//margin-right: -15px;

		&:hover {
			background-color: transparent;
		}

		&:active,
		&:focus {
			outline: none;
			box-shadow: none;
		}
		.fa {
			margin: 0;
		}
		.fa-times {
			color: $danger-color;
		}
	}
}

// MESSAGE DOCK
.message-dock {
	position: fixed;
	bottom: 5%;
	left: 0;
	right: 0;
	z-index: 2000;

	.container {
		list-style: none;
		padding: 0;
	}

	.message-item {
		position: relative;
		background-color: $primary-color;
		color: $light;
		border-radius: 4px;
		padding: 16px;
		padding-right: 48px;
		margin-left: 16px;
		margin-right: 16px;
		margin-bottom: 8px;

		&.error {
			background-color: lighten($danger, 15%);
		}

		.btn {
			position: absolute;
			top: 8px;
			right: 8px;
			width: 40px;
			height: 40px;
			color: $light;
			opacity: 0.8;

			&:hover {
				background-color: transparentize($light, 0.8);
			}
		}
	}
}

// MODAL
.modal-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: transparentize($gray-dark, 0.4);
	z-index: 1000;
	display: flex !important;
	justify-content: space-around;
	align-items: center;

	.header-group {
		display: flex;
		place-content: space-between;

		span {
			font-size: 20px;
			font-weight: bold;
		}

		i {
			position: relative;
			top: 10 !important;
			right: 10 !important;
		}

		.fa-times:hover {
			cursor: pointer;
		}
	}

	.modal {
		position: relative;
		top: 100;
		left: 100;
		background: $gray-lighter;
		width: calc(100% - 32px);
		padding: 16px 32px;
		display: block !important;
		height: auto;

		&.modal-lg {
			@media (min-width: 992px) {
				max-width: 800px;
				width: 800px;
			}
		}
	}
}
.modal-title-icon {
	position: absolute;
	top: -33px;
	left: 48%;
	font-size: 67px;
	background-color: #fff;
	border-radius: 50%;
	width: 65px;
	height: 65px;
	text-align: center;
	&.modal-title-error {
		color: #d93855;
	}
	&.modal-title-success {
		color: #28a745;
	}
}

// LABELS, INPUTS N' VALUES
label {
	font-weight: bold;
	color: $gray;
	line-height: 1;
	display: block;
	letter-spacing: normal;
	text-transform: none;
	width: 100%;
	&.required:after {
		content: '*';
		color: $danger-color;
		margin-left: 2px;
	}

	span {
		font-size: 0.7rem;
	}
}

.label {
	color: $gray;
	line-height: 1;
	display: block;
	letter-spacing: normal;
	text-transform: none;
	width: 100%;

	.opt {
		color: $gray;
		margin-left: 8px;
		font-style: italic;
	}

	h2,
	h3 {
		margin: 0;
	}

	&.inline {
		display: inline-block;
		margin-right: 8px;
	}
}

.label-inline {
	color: $gray;
	line-height: 1;

	&:not(h1):not(h2):not(h3):not(h4) {
		font-size: 14px;
		font-weight: normal;
	}
}

.label-button-group {
	display: grid !important;
	align-items: center;
	grid-template-columns: auto max-content;
	min-height: 40px;

	.item-count {
		font-size: 14px;
		font-weight: normal;
		display: block;
		margin-top: 8px;

		@media (min-width: 700px) {
			margin-top: -8px;
			display: inline;
			margin-left: 16px;
		}
	}

	.btn-left,
	.btn-right {
		border: none;
		height: 40px;
		margin-top: -8px;
		margin-bottom: -8px;
		margin-left: -28px;
		background-color: transparent;
		width: 28px;
		text-align: center;

		@media (min-width: 700px) {
			width: 40px;
			margin-left: -40px;
		}

		.fa {
			font-size: 20px;
		}

		&:hover {
			background-color: transparent;

			.fa {
				color: $gray-dark;
			}
		}
	}
}

.value {
	font-size: 22px;
	font-weight: 700;
	line-height: 1.4;
	margin-top: 0;
	margin-bottom: 0;

	@media (min-width: 700px) {
		font-size: 28px;
	}
}

input[type='text'],
input[type='date'],
input[type='number'],
input[type='tel'],
input[type='month'],
select,
textarea {
	&:hover {
		background-color: transparentize($light, 0.2);
	}

	&:focus {
		color: $dark;
		background-color: transparentize($light, 0.1);
	}
}

.form-control {
	background-color: $light;
	border: 2px solid $gray-light;
	border-radius: 2px;
	color: $texto;
	display: block;
	width: 100%;
	line-height: 1.5;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	/*margin-top: 6px; */
	height: auto;
	padding: 10px 12px;
	font-size: 1em;
	//&:not[readonly]:focus {
	&:focus {
		border-color: $primary-color;
		box-shadow: 0 0 8px darken(rgba($primary-color, 0.5), 25%);
		outline: 0 none;
	}
	&:disabled,
	&[readonly] {
		&:focus {
			box-shadow: none;
			border-color: $gray-light;
		}
	}
}

.form-control {
	/*display: flex;
	position: relative;*/
	&[class*='col-'] {
		margin-left: -16px;
	}
}

.form-control.form-control-error {
	border: 2px solid $danger-color;
}

textarea {
	height: auto;
	line-height: 1.4;
}

input[type='radio'] {
	height: 14px;
	width: 18px;
	margin-right: 8px;
	cursor: pointer;

	&:focus {
		outline-offset: 4px;
	}
}

input[type='radio'] + .label {
	display: inline-block;
	color: $gray;
	font-size: 16px;
	cursor: pointer;
}

textarea[readonly]:not(.ant-input),
textarea[readonly]:hover:not(.ant-input),
textarea[readonly]:focus:not(.ant-input),
input[readonly]:not(.ant-input),
input[readonly]:hover:not(.ant-input),
input[readonly]:focus:not(.ant-input) {
	color: $gray;
	background-color: $gray-lighter;
	cursor: not-allowed;
}

.input-table {
	.input-table-header {
		display: none;
	}

	.input-row {
		margin-bottom: 8px;
	}

	.row-label {
		margin-bottom: 0;
		margin-top: 8px;
		font-weight: bold !important;
		color: $gray;
	}

	.input-group:not(:last-child) {
		margin-bottom: 16px;
	}

	@media (min-width: 510px) {
		.input-group:not(:last-child) {
			margin-bottom: 0;
		}

		.row-label {
			margin-bottom: 32px;
		}

		.input-row {
			.label {
				display: none;
			}
		}

		.input-table-header {
			display: flex;
			margin-bottom: 0;

			.label {
				display: block;
			}
		}
	}
}

.info {
	white-space: pre-wrap;
	text-align: justify;
}

.calendar-no-border {
	border: 0px !important;
	width: fit-content !important;
}

.calendar-no-border:focus {
	outline: none;
	border-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.calendar-container {
	display: flex;
}

.checkbox-input {
	display: flex !important;
	align-items: baseline;
	align-self: baseline;
	align-content: flex-end;
	padding: 10px;
	margin-right: 10px;

	input {
		cursor: pointer;
		margin-right: 15px;
		transform: scale(1.6);

		&:focus {
			box-shadow: 0 0 4px darken($primary-color, 15%);
			outline: 0 none;
		}
	}

	label {
		cursor: pointer;
		font-size: 1.125rem;
		line-height: 1.5;
		// padding-left: 1.5em !important;
		&.problemas {
			font-size: 1rem;
			font-weight: 400;
		}
	}
	&:hover {
		background-color: #f0f0f0;
	}
}
.problema-nome {
	font-size: 1.25rem;
	font-weight: 700;
}

.debug-message {
	color: grey;
	font-size: 9pt;
	white-space: nowrap;
}

.form-group {
	margin-bottom: 20px;
	display: block;
	position: relative;
	width: 100%;

	label {
		@extend .label;
		margin-bottom: 8px;
	}

	small {
		font-size: 0.875rem;
		//margin-top: 0;
		//margin-bottom: 0.5rem;
		color: $texto-secundario;
		//padding: 0;
	}

	.description {
		margin: 0;
	}
}

.title-with-button {
	.selected-value {
		display: inline-block;
		margin-top: 8px;
		padding: 8px 0;
	}

	&.read-only {
		background-color: $gray-lighter;

		&::before {
			content: var(--readOnlyMessage);
		}
	}
}

.input-with-icon {
	position: relative;

	i {
		position: absolute;
		right: 2px;
		top: 2px;
		font-size: 16px;
		padding: 10px 12px;
		line-height: inherit;
	}
}

.results-list {
	background-color: $light;
	padding: 0;
	list-style: none;
	border: 1px solid $gray-light;

	.results-item {
		padding: 16px;

		.results-anchor {
			margin: -16px;
			padding: 16px 32px;
			color: inherit;
			display: block;

			.input-group {
				margin-bottom: 0;

				.label {
					margin-bottom: 8px;
				}

				.value {
					line-height: 1;
				}
			}

			.box {
				margin-top: 16px;
			}
		}

		&:not(:last-child) {
			border: solid 1px transparent;
			border-bottom-color: $gray-light;
		}

		&:hover,
		.results-anchor:hover {
			cursor: pointer;
		}
	}
}

.floating-list-wrapper {
	position: relative;
}

.input-floating-list {
	display: block;
	position: absolute;
	width: 600px;
	max-width: 100%;
	// border: 1px solid $gray-dark;
	border: 1px solid rgba(0, 0, 0, 0.15);
	background-color: $light;
	top: 100%;
	max-height: 240px;
	overflow-y: auto;
	z-index: 10;
	box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);

	.results-count {
		color: $gray;
		margin: 8px 16px;
	}

	.results-list {
		margin-top: 0 !important;
		margin-bottom: 0;
		border: none;
		border-top: 1px solid rgba(0, 0, 0, 0.15);

		.results-item {
			padding-top: 8px;
			padding-bottom: 8px;

			&:hover,
			.results-anchor:hover,
			&.fake-focus,
			.results-anchor.fakefocus {
				background-color: $primary-color;
				color: $light;
				> * {
					color: $light;
				}
			}
		}

		.form-control {
			padding: unset;
			border: unset;
			background: unset;
		}
	}
}

.areas-home {
	display: flex;
	flex-direction: column;

	.abas {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		margin-bottom: 25px;

		.aba {
			border: 2px solid $dark;
			border-right: 0px;
			border-bottom: 0px;
			border-radius: 25px;
			padding: 15px;

			&.selected {
				border-color: $secondary-color;
				border-width: 4px;
			}
		}

		.filler {
			flex: 1;
		}
	}
}

input[type='radio'] {
	height: 24px;
	width: 28px;
	margin-right: 8px;
	cursor: pointer;

	&:focus {
		outline-offset: 4px;
	}
}

input[type='radio'] + .label {
	display: inline-block;
	color: $gray-dark;
	font-size: 16px;
	cursor: pointer;
}

.form-check-label {
	/* margin-bottom: 0; */
	display: flex !important;
	align-items: center;
}

.radio-field {
	display: flex;
	margin-top: 8px;

	&.stack {
		flex-direction: column;
	}

	.radio-options {
		display: flex;
		align-items: center;

		.radio-label {
			display: flex;
			align-items: center;
			font-weight: 400;
			.radio-input {
				font-family: inherit;
			}
		}
	}
}

.align-xl-flex-end {
	@include media-breakpoint-up(xl) {
		align-items: flex-end;
	}
}

.align-form-xl {
	@include media-breakpoint-up(xl) {
		margin-top: 24px;
	}
}

//Radios estilo portal PMPA - não funciona sem validação html!
/*
$input-border: $gray-light;
$input-action: $primary-color;
$input-disabled-border: $input-border;
$input-disabled-bg: $gray-lighter;
$cursor-disabled: not-allowed;
$input-bg: #fff;

.radio {
	margin-top: 10px;
	margin-bottom: 10px;
	> [type='radio']:not(:checked),
	> [type='radio']:checked {
		position: absolute;
		left: -9999px;
		+ label {
			position: relative;
			padding-left: 32px;
			cursor: pointer;
			font-weight: 400;

			&:before {
				position: absolute;
				left: 0;
				top: 2px;
				width: 24px;
				height: 24px;
				content: '';
				border: 2px solid $input-border;
				background: #fff;
				border-radius: 50%;
			}
			&:hover:before {
				border-color: $input-action;
			}

			&:after {
				position: absolute;
				//@include fa;
				//content: '\f111';
				//font-size: 8px;
				//line-height: 0.8;
				//color: #fff;
				//padding: 6px 5px;
				background-color: $input-action;
				content: '';
				width: 12px;
				height: 12px;
				top: 8px;
				left: 6px;
				transition: all 0.2s;
				border-radius: 50%;
			}
		}
	}
	[type='radio']:checked {
		+ label {
			&:before {
				border: 2px solid $input-action;
			}
		}
	}
*/
/* checked mark aspect changes */
/*
	[type='radio']:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}
	[type='radio']:checked + label:after {
		opacity: 1;
		transform: scale(1);
	}
	*/
/* disabled radio */
/*
	[type='radio']:disabled:not(:checked) + label:before,
	[type='radio']:disabled:checked + label:before {
		border-color: $input-disabled-border;
		background-color: $input-disabled-bg;
		&:hover {
			border-color: $input-disabled-border;
		}
	}
	[type='radio']:disabled:checked + label:after {
		//color: $input-disabled-bg;
		background-color: $input-disabled-border;
	}
	[type='radio']:disabled + label {
		color: $texto-secundario;
		cursor: $cursor-disabled;
	}
	*/
/* accessibility */
/*
	[type='radio']:checked:focus + label:before,
	[type='radio']:not(:checked):focus + label:before {
		border-color: $input-action;
		box-shadow: 0 0 5px $input-action;
		outline: 0 none;
	}
}
*/

.analise-item {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
}

.analise-componente {
	padding: 0px;
}

.analise-buttons {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
}

.analise-badge {
	padding: 0 0.5rem;
	border-radius: 0.25rem;
	color: $light;

	&:before {
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&.confirmada {
		color: $verde-sucesso;
		border: 1px solid $verde-sucesso;
		&:before {
			content: '\f00c';
			margin-right: 0.25rem;
		}
	}

	&.impugnada {
		color: $danger;
		border: 1px solid $danger;
		&:before {
			content: '\f05e';
			margin-right: 0.25rem;
		}
	}
}

.info-participante {
	p {
		margin-bottom: 0.5rem;
	}
}

.votacao-container {
	min-height: 100%;
	width: 100%;
	margin: auto;
}

.votacao-cpf {
	font-size: 1.5rem;
}

.votacao-ok-button {
	font-size: 1.5rem;
}

.input-group .form-control + .btn {
	margin-left: -2px;
}

.pesquisa {
	.btn-outline-danger {
		position: absolute;
		z-index: 3 !important;
		right: 7.125rem;
		border-color: transparent;
	}
	.btn:last-child {
		margin-left: -2px;
		z-index: 4;
	}
}

.btn.icon-only {
	.fa {
		margin: 0;
	}
}

.icone-copiar {
	.fa {
		margin-left: 0.25rem;
		font-size: 87.5%;
		color: $primary-color;
	}
}

.votacao-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
}

.votacao-item {
	position: relative;
	border: 2px solid $gray-light;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 1 200px;
	cursor: pointer;
	padding: 1.3rem;

	&.votado {
		cursor: default;
	}

	.votacao-tipo {
		font-size: 2rem;
		color: $primary-color;
		text-align: center;

		&.votado {
			color: $texto-secundario;
		}
	}

	.votacao-obs {
		font-size: 1rem;
		color: $texto-secundario;
		text-align: center;
		&:empty {
			display: none;
		}
	}

	.computado {
		position: absolute;
		left: -5px;
		top: 24px;
		transform: rotate(-45deg);
		color: #fff;
		font-size: 0.75rem;
		font-weight: bold;
		background-color: $verde;
		letter-spacing: 0.5px;
		padding: 0.375rem 0;
		line-height: 1;
		&.delegado {
			top: 30px;
		}
		&:before,
		&:after {
			content: '';
			width: 24px;
			height: 24px;
			position: absolute;
			top: 0;
		}
		&:before {
			left: -24px;
			border-right: 12px solid $verde;
			border-bottom: 12px solid $verde;
			border-left: 12px solid transparent;
			border-top: 12px solid transparent;
		}
		&:after {
			right: -24px;
			border-left: 12px solid $verde;
			border-top: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid $verde;
		}
	}
}

.votacao-opcoes {
	display: grid;
	grid-template-columns: repeat(auto-fill, minMax(350px, 1fr));
	grid-auto-rows: auto;
	grid-gap: 1rem;
	margin: 1rem 0px;
}

.votacao-opcao {
	border: 5px solid $gray-light;
	border-radius: 4px;
	padding: 1rem;
	cursor: pointer;

	&.selected {
		border: 5px solid $primary-color;
	}

	.votacao-chapa-numero {
		color: $primary-color;
	}

	.votacao-chapa-regiao {
		margin: 1rem 0px;
	}
}

.msg-confirma-voto {
	font-size: 1.375rem;
}

.icon-inside {
	position: relative;

	i {
		position: absolute;
		right: 25px;
		top: 7px;
		padding: 10px;
		color: $danger;
	}
}

.modal-body {
	padding: 1.5rem;
	+ .modal-body {
		padding-top: 0;
	}
	.form-group:last-child {
		margin-bottom: 0;
	}
}

.modal-footer > .buttons {
	margin: unset;
}

.modal-header,
.modal-footer {
	padding: 1rem 1.5rem;
}

.modal-header h1 {
	margin-bottom: 0;
}

.apuracao-header {
	font-size: 1.25rem;
	font-weight: 700;
	.list-items + & {
		margin-top: 1.5rem;
	}
}

.apuracao-chapas,
.apuracao-delegados {
	margin: 2rem 0rem;
}

// .row.eleita {
// 	// se for o primeiro filho do seu container
// 	&:first-child {
// 		border-top-width: 2px;
// 	}
// }
.row.eleita {
	border: 2px solid $verde-sucesso;
	border-collapse: collapse;
	color: $verde-sucesso;
	background-color: mix($verde-sucesso, $light, 12%);
	&.even {
		background-color: mix($verde-sucesso, $light, 25%);
	}
}

// .row.empatada {
// 	// se for o primeiro filho do seu container
// 	&:first-child {
// 		border-top-width: 2px;
// 	}
// }
.row.empatada {
	border: 2px solid $amarelo-sucesso;
	border-collapse: collapse;
	color: $amarelo-sucesso;
	background-color: mix($secondary-color, $light, 12%);
	&.even {
		background-color: mix($secondary-color, $light, 25%);
	}
	+ .row.eleita {
		border-top: unset;
	}
}

.row:not(:first-child) {
	border-top: unset;
}

.header-button {
	//position: absolute;
	//right: 0px;
	//font-size: 1.5rem;
	color: lighten($secondary-color, 5%);
	background-color: transparent;
	border: none;
	&:hover,
	&:focus {
		color: lighten($secondary-color, 5%);
		text-decoration: none;
		outline: none;
		box-shadow: none;
	}
}

.user-menu {
	display: flex;
	gap: 0.5rem;
	position: relative;
}

.side-menu-wrapper {
	position: absolute;
	right: 8px;
	//top: 36px;
	z-index: 1000;

	.side-menu {
		margin-top: 20px;
		box-shadow: 0px 0px 0px 1px lightgrey;
		width: 420px;
		background-color: $light;
		position: relative;
		min-height: 40px;
		max-height: calc(100vh - 200px);
		overflow-y: auto;
		border: 2px solid $gray-dark;

		@media (min-width: 700px) {
			max-height: calc(100vh - 64px);
		}

		.menu-section {
			list-style: none;
			padding: 16px 0;
			margin: 0;

			&:not(:last-of-type) {
				border-bottom: solid 1px $gray-light;
			}

			li {
				&.list-title {
					padding: 8px 32px;
					color: $gray;
				}

				button,
				.menu-button {
					height: 40px;
					width: 100%;
					text-align: left;
					padding: 0 32px;
					display: flex;

					.fa {
						width: 40px;
						min-width: 40px;
						line-height: 1.4;
						flex: 0 0;
					}
				}

				&.username {
					padding: 8px 32px;
					height: 40px;
					color: $gray;
				}
			}
		}
	}
}

.btn-debug {
	background-color: yellow;
	color: red;

	&:hover {
		background-color: darken(yellow, 5%);
	}

	.fa {
		font-size: 1.2em;
		font-weight: normal;
		margin-left: 5px;
	}
}

.flex {
	width: 70%;
	display: flex;
	gap: 3rem;
	align-items: center;
	white-space: nowrap;
	* {
		margin: 0;
		padding: 0;
	}
	margin-top: 5rem;
}
